






































import Vue from "vue";
import {
  extract_skill_group_styling,
  get_skills_group
} from "@/utils/skills_group";

export default Vue.extend({
  name: "SkillsGroup",
  props: {
    active_skill_group: {
      type: String,
      required: true,
      default: ""
    }
  },
  methods: {
    get_skills_group,
    extract_skill_group_styling
  }
});
